@font-face {
  font-family: "OpenSans";
  src: url("../../src/font/OpenSans/OpenSans-Light.woff") format("woff"), url("../../src/font/OpenSans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "OpenSans";
  src: url("../../src/font/OpenSans/OpenSans-Regular.woff") format("woff"), url("../../src/font/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "OpenSans";
  src: url("../../src/font/OpenSans/OpenSans-RegularItalic.woff") format("woff"), url("../../src/font/OpenSans/OpenSans-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "OpenSans";
  src: url("../../src/font/OpenSans/OpenSans-SemiBold.woff") format("woff"), url("../../src/font/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "OpenSans";
  src: url("../../src/font/OpenSans/OpenSans-Bold.woff") format("woff"), url("../../src/font/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: OpenSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #fff;
  text-align: left;
  background-color: #2B2A2A; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #FF4B31;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #e41d00;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1400px) {
    .container {
      max-width: 1340px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.3333333333%; }
  .offset-xxl-2 {
    margin-left: 16.6666666667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.3333333333%; }
  .offset-xxl-5 {
    margin-left: 41.6666666667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.3333333333%; }
  .offset-xxl-8 {
    margin-left: 66.6666666667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.3333333333%; }
  .offset-xxl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #2B2A2A; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffcdc5; }

.table-hover .table-primary:hover {
  background-color: #ffb7ac; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ffb7ac; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #2B2A2A;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #2B2A2A;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 1.125rem 1.875rem;
  font-size: 1rem;
  line-height: 1.375;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: rgba(255, 255, 255, 0.8);
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.6);
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: rgba(255, 255, 255, 0.4);
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: rgba(255, 255, 255, 0.05);
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(3.625rem + 2px); }

select.form-control:focus::-ms-value {
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(1.125rem + 1px);
  padding-bottom: calc(1.125rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.375; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  margin-bottom: 0;
  line-height: 1.375;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1.875rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #2B2A2A, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #2B2A2A, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 1.125rem 2.5rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 75, 49, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #FF4B31;
  border-color: #FF4B31; }
  .btn-primary:hover {
    color: #fff;
    background-color: #ff2a0b;
    border-color: #fd2000; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 75, 49, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #FF4B31;
    border-color: #FF4B31; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #fd2000;
    border-color: #f01e00; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 75, 49, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #FF4B31;
  background-color: transparent;
  background-image: none;
  border-color: #FF4B31; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #FF4B31;
    border-color: #FF4B31; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 75, 49, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #FF4B31;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #FF4B31;
    border-color: #FF4B31; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 75, 49, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #FF4B31;
  background-color: transparent; }
  .btn-link:hover {
    color: #e41d00;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #2B2A2A;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #2B2A2A; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 1; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.375; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #FF4B31 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #fd2000 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #FF4B31 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #FF4B31 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #fd2000 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.c-selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden; }
  .c-selectric-focus .c-selectric {
    border-color: #aaaaaa; }
  .c-selectric-hover .c-selectric {
    border-color: #c4c3c3; }
    .c-selectric-hover .c-selectric .button {
      color: #a2a1a1; }
      .c-selectric-hover .c-selectric .button:after {
        border-top-color: #a2a1a1; }
  .c-selectric-open {
    z-index: 9999; }
    .c-selectric-open .c-selectric {
      border-color: #c4c3c3; }
      .c-selectric-open .c-selectric-items {
        display: block; }
  .c-selectric-disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default;
    user-select: none; }
  .c-selectric-wrapper {
    position: relative;
    cursor: pointer; }
  .c-selectric-responsive {
    width: 100%; }
  .c-selectric-hide-select {
    position: relative;
    overflow: hidden;
    width: 0;
    height: 0; }
    .c-selectric-hide-select select {
      position: absolute;
      left: -100%; }
    .c-selectric-hide-select.c-selectric-is-native {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10; }
      .c-selectric-hide-select.c-selectric-is-native select {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        border: none;
        z-index: 1;
        box-sizing: border-box;
        opacity: 0; }
  .c-selectric-temp-show {
    position: absolute !important;
    visibility: hidden !important;
    display: block !important; }
  .c-selectric-input {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    outline: none !important;
    border: none !important;
    *font: 0/0 a !important;
    background: none !important; }
  .c-selectric-items {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #F8F8F8;
    border: 1px solid #c4c3c3;
    z-index: -1;
    box-shadow: 0 0 10px -6px; }
    .c-selectric-items .c-selectric-scroll {
      height: 100%;
      overflow: auto; }
    .c-selectric-items .c-selectric-above .c-selectric-items .c-selectric {
      top: auto;
      bottom: 100%; }
    .c-selectric-items .c-selectric-group .c-selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #444; }
    .c-selectric-items .c-selectric-group.disabled li {
      filter: alpha(opacity=100);
      opacity: 1; }
    .c-selectric-items .c-selectric-group li {
      padding-left: 25px; }
    .c-selectric-items ul, .c-selectric-items li {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 12px;
      line-height: 20px;
      min-height: 20px; }
    .c-selectric-items li {
      display: block;
      padding: 10px;
      color: #666;
      cursor: pointer; }
      .c-selectric-items li.selected {
        background: #E0E0E0;
        color: #444; }
      .c-selectric-items li.highlighted {
        background: #D0D0D0;
        color: #444; }
      .c-selectric-items li:hover {
        background: #D5D5D5;
        color: #444; }
    .c-selectric-items .disabled {
      filter: alpha(opacity=50);
      opacity: 0.5;
      cursor: default !important;
      background: none !important;
      color: #666 !important;
      user-select: none; }
  .c-selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 38px 0 10px;
    font-size: 12px;
    line-height: 38px;
    color: #444;
    height: 38px;
    user-select: none; }
  .c-selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    line-height: 38px;
    background-color: #F8f8f8;
    color: #BBB;
    text-align: center;
    font: 0/0 a; }
    .c-selectric .button:after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: #BBB;
      border-bottom: none; }

.text-hide {
  margin: 0;
  padding: 0; }

body {
  fill: #fff; }

@media (max-width: 1399.98px) {
  html {
    font-size: 14px; } }

* {
  outline: none !important; }

button,
.btn,
a {
  transition: 0.3s ease-in-out; }

textarea {
  resize: vertical; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 700;
  display: block; }

h1, .h1 {
  font-size: 3.375rem;
  line-height: 1.11111; }

h2, .h2 {
  font-size: 2.875rem;
  line-height: 1.26087; }

h3, .h3 {
  font-size: 2.25rem;
  line-height: 1.388889; }

h4, .h4 {
  font-size: 1.875rem;
  line-height: 1.366667; }

h5, .h5 {
  font-size: 1.5rem;
  line-height: 1.375; }

textarea {
  resize: vertical; }

abbr[title] {
  text-decoration: none;
  border-bottom: 0;
  cursor: inherit !important; }

main {
  display: flex; }

.o-box {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1920px;
  padding: 0 90px;
  position: relative; }
  @media (max-width: 767.98px) {
    .o-box {
      padding: 0; } }
  .o-box:before, .o-box:after {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.15); }
    @media (max-width: 767.98px) {
      .o-box:before, .o-box:after {
        content: none; } }
  .o-box:before {
    left: 89px; }
  .o-box:after {
    right: 89px; }
  .o-box-wrap {
    padding: 0 70px; }
    @media (max-width: 1399.98px) {
      .o-box-wrap {
        padding: 0 40px; } }
    @media (max-width: 767.98px) {
      .o-box-wrap {
        padding: 0 20px; } }

.form-row {
  margin-right: -20px;
  margin-left: -20px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 20px;
    padding-left: 20px; }

form {
  position: relative; }
  form.is-success .form-success {
    visibility: visible;
    opacity: 1; }

.form-success {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2B2A2A;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  text-align: center; }

.was-validated .form-control:valid:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-valid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-valid:focus,
.custom-select.is-invalid:focus {
  box-shadow: none; }

.c-carousel img {
  width: 100%;
  height: auto; }

.c-carousel-control {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around; }

.c-carousel-counter {
  color: rgba(255, 255, 255, 0.2);
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 1.375; }
  .c-carousel-counter__active {
    color: #fff; }
  .c-carousel-counter__total {
    font-size: 1.375rem;
    line-height: 1.333333;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 10px;
    vertical-align: super; }

.c-carousel-slide {
  display: flex;
  vertical-align: middle;
  align-items: center;
  width: 100%; }

.c-carousel-btn {
  background: none;
  box-shadow: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  padding: 0;
  cursor: pointer; }
  .c-carousel-btn:hover, .c-carousel-btn:focus {
    opacity: 1;
    color: #FF4B31; }
  .c-carousel-btn[data-direction=next] svg {
    transform: rotate(180deg); }

.c-carousel .slick-slider, .c-carousel .slick-list, .c-carousel .slick-track {
  display: flex;
  width: 100%;
  flex-shrink: 0; }

.c-carousel .slick-slider.slick-initialized .slick-slide {
  flex-shrink: 0;
  display: flex; }

.btn {
  border: none; }

.c-banner {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  font-size: 1.125rem;
  line-height: 1.333;
  z-index: 2;
  display: flex;
  flex-direction: column; }
  @media (max-width: 1199.98px) {
    .c-banner {
      display: flex;
      transition: height 0.3s ease-in-out;
      background: #2B2A2A;
      position: fixed;
      height: 73px;
      top: 0;
      left: 0;
      width: 100%;
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: 1px; } }
  .c-banner-main {
    width: 100%;
    padding: 30px 0;
    flex-shrink: 0; }
    @media (max-width: 1199.98px) {
      .c-banner-main {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        padding: 15px 0; } }
    @media (max-width: 767.98px) {
      .c-banner-main {
        background: #2B2A2A;
        position: relative;
        z-index: 2; } }
    .c-banner-main__wrap {
      width: 100%;
      display: flex;
      align-items: flex-end; }
      @media (max-width: 1199.98px) {
        .c-banner-main__wrap {
          align-items: center; } }
    @media (max-width: 1199.98px) {
      .c-banner-main:after {
        content: '';
        position: absolute;
        width: calc(100% + 80px);
        left: -40px;
        top: 100%;
        height: 1px;
        opacity: 0;
        background: rgba(255, 255, 255, 0.15); } }
  @media (max-width: 1199.98px) {
    .c-banner-logo {
      width: 100px; } }
  .c-banner-logo__link {
    display: block;
    margin-bottom: 4px; }
    @media (max-width: 1199.98px) {
      .c-banner-logo__link {
        margin-bottom: 0; } }
  .c-banner-logo img {
    max-width: 100%;
    height: auto; }
  .c-banner-language {
    display: flex;
    justify-content: flex-end;
    margin-left: auto; }
    .c-banner-language .c-selectric {
      border: none;
      background: none;
      opacity: .6; }
      .c-banner-language .c-selectric-wrap {
        margin-left: auto; }
      .c-banner-language .c-selectric .label {
        width: auto;
        height: auto;
        color: currentColor;
        font-size: 1.125rem;
        line-height: 1.33333;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
        margin-right: 19px; }
      .c-banner-language .c-selectric .button {
        background: none;
        border: none;
        width: auto;
        height: 100%;
        display: flex;
        align-items: center; }
        .c-banner-language .c-selectric .button:after, .c-banner-language .c-selectric .button::before {
          content: none; }
        .c-banner-language .c-selectric .button svg {
          margin: auto; }
      .c-banner-language .c-selectric-hover .c-selectric, .c-banner-language .c-selectric-open .c-selectric {
        opacity: 1; }
      .c-banner-language .c-selectric-items {
        left: inherit;
        right: 0;
        background: #333232;
        border: none;
        margin-top: 10px; }
        .c-banner-language .c-selectric-items ul,
        .c-banner-language .c-selectric-items li {
          font-size: 1.125rem;
          line-height: 1.3333;
          text-transform: uppercase;
          font-weight: 600;
          text-align: center;
          color: currentColor; }
        .c-banner-language .c-selectric-items li {
          padding: 7px 10px;
          opacity: .6; }
          .c-banner-language .c-selectric-items li.selected, .c-banner-language .c-selectric-items li.highlighted {
            background: none;
            color: #FF4B31;
            opacity: 1; }
          .c-banner-language .c-selectric-items li:hover {
            background: none;
            opacity: 1; }
  .c-banner-hamburger {
    display: none;
    height: 20px;
    align-items: center;
    margin-left: 40px; }
    @media (max-width: 1199.98px) {
      .c-banner-hamburger {
        display: flex; } }
    .c-banner-hamburger__link {
      display: block;
      color: #fff;
      margin: auto; }
      .c-banner-hamburger__link:hover {
        color: #FF4B31; }
    .c-banner-hamburger__icon {
      display: block;
      width: 30px;
      height: 16px;
      position: relative; }
      .c-banner-hamburger__icon > div {
        position: absolute;
        width: 100%;
        right: 0;
        height: 2px;
        background: currentColor;
        transition: top 0.3s ease-in-out, right 0.3s ease-in-out, transform 0.3s ease-in-out, width 0.3s ease-in-out; }
        .c-banner-hamburger__icon > div:first-child {
          top: 0; }
        .c-banner-hamburger__icon > div:nth-child(2) {
          top: calc(50% - 1px); }
        .c-banner-hamburger__icon > div:last-child {
          bottom: 0; }
  .c-banner-nav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    display: flex; }
    @media (max-width: 1199.98px) {
      .c-banner-nav {
        overflow: hidden;
        position: static;
        transform: none;
        flex-grow: 1; } }
    @media (max-width: 1199.98px) {
      .c-banner-nav__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch; } }
    .c-banner-nav__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex; }
      @media (max-width: 1199.98px) {
        .c-banner-nav__list {
          padding: 20px 0;
          margin: auto;
          flex-direction: column;
          text-align: center;
          align-items: center; } }
    @media (max-width: 1199.98px) {
      .c-banner-nav__item {
        padding: 20px 0; } }
    .c-banner-nav__item.is-active .c-banner-nav__link {
      opacity: 1; }
      .c-banner-nav__item.is-active .c-banner-nav__link:after {
        opacity: 1;
        visibility: visible; }
    .c-banner-nav__link {
      color: currentColor;
      opacity: 0.6;
      padding: 0 20px;
      display: block;
      position: relative; }
      .c-banner-nav__link:hover, .c-banner-nav__link:focus {
        opacity: 1;
        color: currentColor;
        text-decoration: none; }
      .c-banner-nav__link:after {
        content: '';
        position: absolute;
        left: 20px;
        right: 20px;
        top: calc(100% + 8px);
        height: 3px;
        display: block;
        background: #FF4B31;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease-in-out; }
    @media (min-width: 1200px) {
      .c-banner-nav .o-box {
        padding: 0; } }
    @media (max-width: 1199.98px) {
      .c-banner-nav .o-box {
        display: flex; } }
    .c-banner-nav .o-box-wrap {
      width: 100%; }
      @media (min-width: 1200px) {
        .c-banner-nav .o-box-wrap {
          padding: 0; } }
      @media (max-width: 1199.98px) {
        .c-banner-nav .o-box-wrap {
          display: flex; } }
  .c-banner-category {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 89px;
    display: flex;
    z-index: 1; }
    @media (max-width: 1199.98px) {
      .c-banner-category {
        padding-top: 73px; } }
    @media (max-width: 767.98px) {
      .c-banner-category {
        padding: 0;
        position: static;
        width: 100%;
        height: auto;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        flex-shrink: 0;
        background: #2B2A2A;
        transition: transform 0.3s ease-in-out; } }
    .c-banner-category__wrap {
      display: flex;
      width: 100%; }
    .c-banner-category__list {
      width: 100%;
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: center;
      justify-content: center;
      background: #2B2A2A; }
      @media (max-width: 767.98px) {
        .c-banner-category__list {
          flex-direction: row; } }
    .c-banner-category__item {
      transition: 0.3s ease-in-out;
      padding: 10px 0; }
      @media (max-width: 767.98px) {
        .c-banner-category__item {
          padding: 0 10px; } }
      @media (max-width: 600px) {
        .c-banner-category__item {
          padding: 0;
          width: 100%; } }
      .c-banner-category__item.is-active .c-banner-category__link {
        opacity: 1;
        color: #fff;
        box-shadow: 4px 0 10px 0 rgba(0, 0, 0, 0.15);
        background: #333232; }
        .c-banner-category__item.is-active .c-banner-category__link:after {
          opacity: 1; }
    .c-banner-category__icon {
      display: block; }
      .c-banner-category__icon svg {
        vertical-align: middle;
        width: 40px;
        height: 30px; }
    .c-banner-category__link {
      color: #fff;
      opacity: .6;
      display: block;
      padding: 20px 0;
      position: relative; }
      @media (max-width: 767.98px) {
        .c-banner-category__link {
          padding: 20px; } }
      @media (max-width: 600px) {
        .c-banner-category__link {
          padding: 20px 0;
          width: 100%; } }
      .c-banner-category__link:after {
        content: '';
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 3px;
        background: #FF4B31;
        right: 0;
        top: 0;
        transition: 0.3s ease-in-out; }
        @media (max-width: 767.98px) {
          .c-banner-category__link:after {
            height: 3px;
            width: 100%;
            top: inherit;
            bottom: 0; } }
      .c-banner-category__link:hover, .c-banner-category__link:focus {
        opacity: 1;
        color: #fff;
        box-shadow: 4px 0 10px 0 rgba(0, 0, 0, 0.15);
        background: #333232; }
        .c-banner-category__link:hover:after, .c-banner-category__link:focus:after {
          opacity: 1; }
    .c-banner-category .o-box {
      display: flex;
      width: 100%; }
      @media (min-width: 768px) {
        .c-banner-category .o-box {
          padding: 0; } }
      .c-banner-category .o-box-wrap {
        display: flex;
        width: 100%; }
        @media (min-width: 768px) {
          .c-banner-category .o-box-wrap {
            padding: 0; } }
  .c-banner .o-box:before, .c-banner .o-box:after {
    transition: 0.3s ease-in-out;
    opacity: 0; }

@media (min-width: 768px) {
  .is-home-top .c-banner-category__item {
    opacity: 0;
    visibility: hidden; } }

.is-home-top.is-menu-open .c-banner-category__item {
  opacity: 1;
  visibility: visible; }

@media (max-width: 767.98px) {
  .is-scroll-down .c-banner-category {
    transform: translateY(-100%); } }

.is-menu-open .c-banner {
  height: 100%; }
  .is-menu-open .c-banner-hamburger__link {
    color: #FF4B31; }
  .is-menu-open .c-banner-hamburger__icon > div:first-child {
    transform: rotate(225deg);
    top: calc(50% - 1px); }
  .is-menu-open .c-banner-hamburger__icon > div:nth-child(2) {
    width: 0;
    right: 50%; }
  .is-menu-open .c-banner-hamburger__icon > div:last-child {
    transform: rotate(-225deg);
    top: calc(50% - 1px); }
  .is-menu-open .c-banner-nav .o-box:after, .is-menu-open .c-banner-nav .o-box:before {
    opacity: 1; }

@media (max-width: 767.98px) {
  .is-menu-open.is-scroll-down .c-banner-category {
    transform: none; } }

.c-loader {
  position: fixed;
  z-index: 1071;
  background: #2B2A2A;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 15px;
  opacity: 1;
  visibility: visible;
  transition: 1s cubic-bezier(0.25, 0.1, 0.25, 1); }
  .c-loader svg,
  .c-loader img {
    margin: auto;
    max-width: 100%;
    height: auto; }

.is-loaded .c-loader {
  opacity: 0;
  visibility: hidden; }

.c-home-top {
  min-height: 100vh;
  display: flex; }
  @media (max-width: 1199.98px) {
    .c-home-top {
      min-height: auto; } }
  .c-home-top__main {
    margin-top: auto;
    display: flex;
    margin-bottom: 70px;
    width: 100%; }
    @media (max-width: 991.98px) {
      .c-home-top__main {
        flex-wrap: wrap; } }
    @media (max-width: 767.98px) {
      .c-home-top__main {
        margin-bottom: 50px; } }
    .c-home-top__main-col {
      width: 50%;
      flex-shrink: 0; }
      @media (max-width: 991.98px) {
        .c-home-top__main-col {
          width: 100%; } }
      .c-home-top__main-col:not(.t-img) {
        padding-right: 20px; }
        @media (max-width: 991.98px) {
          .c-home-top__main-col:not(.t-img) {
            padding-right: 0; } }
        @media (max-width: 767.98px) {
          .c-home-top__main-col:not(.t-img) {
            order: 1; } }
      .c-home-top__main-col.t-img {
        margin-left: auto;
        width: calc((100% + 140px) * 0.3333);
        margin-right: -70px;
        position: relative;
        display: flex;
        align-items: center;
        margin-top: -4.8%;
        margin-bottom: -10.35%; }
        @media (max-width: 1399.98px) {
          .c-home-top__main-col.t-img {
            width: calc((100% + 80px) * 0.3333);
            margin-right: -40px; } }
        @media (max-width: 767.98px) {
          .c-home-top__main-col.t-img {
            width: calc((100% + 40px) * 0.3333);
            margin-right: -20px;
            margin-bottom: 0; } }
        @media (max-width: 767.98px) {
          .c-home-top__main-col.t-img {
            position: static;
            width: 100%;
            margin: 0; } }
        .c-home-top__main-col.t-img img {
          max-width: 100%;
          height: auto; }
    .c-home-top__main-type {
      display: block;
      margin-bottom: 40px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: rgba(255, 255, 255, 0.6);
      font-weight: 600; }
    .c-home-top__main-title {
      margin-bottom: 30px; }
      @media (max-width: 767.98px) {
        .c-home-top__main-title {
          font-size: 2.875rem;
          line-height: 1.26087; } }
    .c-home-top__main-text {
      margin-bottom: 40px;
      display: block;
      font-size: 1.125rem;
      line-height: 1.555556;
      color: rgba(255, 255, 255, 0.8); }
    .c-home-top__main-img {
      position: absolute;
      top: calc(50% + 37px);
      transform: translateY(-50%);
      right: 0;
      z-index: 1;
      width: 140%;
      text-align: right; }
      @media (max-width: 767.98px) {
        .c-home-top__main-img {
          position: static;
          transform: none;
          text-align: left;
          width: 100%;
          max-width: 400px; } }
    @media (max-width: 767.98px) {
      .c-home-top__main-bg {
        display: none; } }
  .c-home-top__category {
    margin-top: auto;
    width: calc((100% + 140px) * 0.6666);
    margin-left: -70px;
    position: relative;
    z-index: 1; }
    @media (max-width: 1399.98px) {
      .c-home-top__category {
        width: calc((100% + 80px) * 0.6666);
        margin-left: -40px; } }
    @media (max-width: 1199.98px) {
      .c-home-top__category {
        width: calc(100% + 80px);
        margin-left: -40px; } }
    @media (max-width: 767.98px) {
      .c-home-top__category {
        display: none; } }
    .c-home-top__category-title {
      margin-left: 70px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 40px;
      display: block; }
      @media (max-width: 1399.98px) {
        .c-home-top__category-title {
          margin-left: 40px; } }
    .c-home-top__category-list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0;
      border-top: 1px solid rgba(255, 255, 255, 0.15);
      text-align: center; }
      @media (max-width: 1199.98px) {
        .c-home-top__category-list {
          background: #2B2A2A; } }
    .c-home-top__category-item {
      width: 20%;
      display: flex;
      margin-top: -1px; }
    .c-home-top__category-icon {
      display: block; }
    .c-home-top__category-link {
      display: block;
      width: 100%;
      padding: 40px;
      color: #fff;
      border-top: 3px solid transparent; }
      @media (max-width: 991.98px) {
        .c-home-top__category-link {
          padding: 30px; } }
      .c-home-top__category-link:hover, .c-home-top__category-link:focus {
        color: currentColor;
        text-decoration: none;
        background: #333232;
        box-shadow: 0 2px 20px 3px rgba(0, 0, 0, 0.2);
        border-color: #FF4B31; }
        .c-home-top__category-link:hover .c-home-top__category-icon, .c-home-top__category-link:focus .c-home-top__category-icon {
          color: #FF4B31; }
    .c-home-top__category-name {
      display: block;
      text-transform: uppercase;
      font-weight: 600;
      margin-top: 30px; }
      @media (max-width: 991.98px) {
        .c-home-top__category-name {
          margin-top: 25px; } }
    .c-home-top__category svg {
      vertical-align: middle;
      transition: 0.3s ease-in-out; }
      @media (max-width: 991.98px) {
        .c-home-top__category svg {
          height: 40px;
          width: 52px; } }
  .c-home-top .o-box-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  @media (max-width: 767.98px) {
    .c-home-top.u-line--right:after {
      content: none; } }

.c-home-about {
  min-height: 100vh;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: column; }
  @media (max-width: 1199.98px) {
    .c-home-about {
      min-height: auto; } }
  .c-home-about__wrap {
    max-width: 1020px;
    padding: 100px 0;
    margin: auto;
    text-align: center;
    flex-grow: 1; }
    @media (max-width: 1399.98px) {
      .c-home-about__wrap {
        padding: 60px 0; } }
  .c-home-about__title {
    margin-bottom: 40px; }
    .c-home-about__title-text {
      margin-top: 30px; }
      @media (max-width: 767.98px) {
        .c-home-about__title-text {
          font-size: 2.25rem;
          line-height: 1.388889;
          margin-top: 15px; } }
  .c-home-about__text {
    font-size: 1.5rem;
    line-height: 1.583333;
    display: block;
    margin-bottom: 60px; }
    @media (max-width: 600px) {
      .c-home-about__text {
        font-size: 1.25rem; } }
  .c-home-about .o-box-wrap {
    flex-grow: 1;
    display: flex; }

.c-home-category {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding: 110px 0;
  padding-bottom: 210px;
  min-height: 100vh;
  display: flex;
  align-items: center; }
  @media (max-width: 1399.98px) {
    .c-home-category {
      padding: 60px 0;
      padding-bottom: 120px; } }
  @media (max-width: 1199.98px) {
    .c-home-category {
      min-height: auto;
      padding-bottom: 60px; } }
  .c-home-category__row {
    display: flex; }
  .c-home-category__col {
    width: 50%;
    margin-left: auto;
    padding-left: 20px; }
    @media (max-width: 1199.98px) {
      .c-home-category__col {
        width: 100%;
        padding-left: 0; } }
  .c-home-category__title {
    margin-bottom: 65px; }
    @media (max-width: 1399.98px) {
      .c-home-category__title {
        margin-bottom: 40px; } }
    @media (max-width: 767.98px) {
      .c-home-category__title {
        font-size: 2.25rem;
        line-height: 1.388889; } }
  .c-home-category__list {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-bottom: 50px; }
    @media (max-width: 1399.98px) {
      .c-home-category__list {
        padding-bottom: 0; } }
  .c-home-category__item-link {
    color: #fff;
    padding: 15px 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0; }
    @media (max-width: 767.98px) {
      .c-home-category__item-link {
        font-size: 1.5rem;
        line-height: 1.375; } }
    .c-home-category__item-link:after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: 2px;
      left: 0;
      height: 3px;
      background: #FF4B31;
      transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1); }
    .c-home-category__item-link:hover, .c-home-category__item-link:focus {
      text-decoration: none;
      color: currentColor; }
    .c-home-category__item-link.collapsed {
      opacity: 0.6; }
      .c-home-category__item-link.collapsed:after {
        opacity: 0; }
      .c-home-category__item-link.collapsed:hover, .c-home-category__item-link.collapsed:focus {
        opacity: 1; }
  .c-home-category__item-info {
    padding: 35px 0;
    padding-bottom: 45px;
    padding-left: 50px;
    font-size: 1.125rem;
    line-height: 1.666667; }
    @media (max-width: 600px) {
      .c-home-category__item-info {
        padding-left: 0; } }
    .c-home-category__item-info dt {
      letter-spacing: 1px;
      font-size: 1rem;
      line-height: 1.9;
      font-weight: 600;
      text-transform: uppercase;
      opacity: .6;
      float: left;
      margin-right: 10px; }
    .c-home-category__item-info dd {
      margin-bottom: 15px; }
    .c-home-category__item-info p:last-child {
      margin-bottom: 0; }
  .c-home-category__item-collapse .c-carousel {
    opacity: 0; }
    @media (max-width: 1199.98px) {
      .c-home-category__item-collapse .c-carousel {
        opacity: 1; } }
  .c-home-category__item-collapse.show .c-carousel {
    transition-delay: 0.2s;
    transition: 0.3s ease-in-out;
    opacity: 1; }
  .c-home-category__pattern {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 33.3333%; }
    @media (max-width: 1199.98px) {
      .c-home-category__pattern {
        display: none; } }
    .c-home-category__pattern img {
      width: 100%;
      height: auto; }
  .c-home-category .c-carousel {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 33.3333%; }
    @media (max-width: 1199.98px) {
      .c-home-category .c-carousel {
        position: static;
        transform: none;
        width: 100%;
        max-width: 400px;
        position: relative;
        top: inherit;
        left: inherit; } }
    .c-home-category .c-carousel:before {
      content: '';
      width: 100%;
      padding-bottom: 105%;
      display: block; }
      @media (max-width: 1199.98px) {
        .c-home-category .c-carousel:before {
          content: none; } }
    .c-home-category .c-carousel-slider {
      position: absolute;
      width: calc(100% + 80px);
      height: 100%;
      left: 80px;
      top: 0; }
      @media (max-width: 1199.98px) {
        .c-home-category .c-carousel-slider {
          position: static;
          width: 100%;
          margin: 0 auto;
          margin-bottom: 20px;
          margin-top: 40px; } }
    .c-home-category .c-carousel-control {
      position: absolute;
      width: 100%;
      top: calc(100% + 80px);
      left: 0; }
      @media (max-width: 1199.98px) {
        .c-home-category .c-carousel-control {
          position: static;
          justify-content: space-between; } }
  .c-home-category .o-box-wrap {
    width: 100%;
    position: relative; }
  @media (max-width: 1199.98px) {
    .c-home-category.u-line--left:before {
      content: none; } }

.c-home-advantages {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 1.125rem;
  line-height: 1.3333; }
  .c-home-advantages__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap; }
  .c-home-advantages__item {
    width: 25%;
    padding: 81px 68px;
    text-align: center;
    border-top: 3px solid transparent;
    transition: 0.3s ease-in-out; }
    @media (max-width: 1399.98px) {
      .c-home-advantages__item {
        padding: 60px 40px; } }
    @media (max-width: 991.98px) {
      .c-home-advantages__item {
        width: 50%; } }
    @media (max-width: 767.98px) {
      .c-home-advantages__item {
        padding: 30px 20px; } }
    .c-home-advantages__item:hover {
      background: #333232;
      box-shadow: 0 2px 20px 3px rgba(0, 0, 0, 0.2);
      border-color: #FF4B31; }
      .c-home-advantages__item:hover .c-home-advantages__icon {
        color: #FF4B31; }
  .c-home-advantages__icon {
    display: block;
    margin-bottom: 30px; }
    @media (max-width: 767.98px) {
      .c-home-advantages__icon {
        margin-bottom: 20px; } }
  .c-home-advantages__text {
    display: block; }
  .c-home-advantages svg {
    vertical-align: middle;
    transition: 0.3s ease-in-out; }

.c-home-history {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 1.125rem;
  line-height: 1.666667;
  padding-top: 110px;
  padding-bottom: 140px; }
  @media (max-width: 1399.98px) {
    .c-home-history {
      padding: 60px 0; } }
  .c-home-history__pattern {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 33.3333%; }
    .c-home-history__pattern img {
      width: 100%;
      height: auto; }
  .c-home-history__title {
    text-align: center;
    margin: 0;
    margin-bottom: 80px; }
    @media (max-width: 1399.98px) {
      .c-home-history__title {
        margin-bottom: 40px; } }
    @media (max-width: 767.98px) {
      .c-home-history__title {
        font-size: 2.25rem;
        line-height: 1.388889; } }
  .c-home-history__quotes {
    color: #333232;
    margin-bottom: 60px;
    text-align: center; }
    @media (max-width: 1399.98px) {
      .c-home-history__quotes {
        margin-bottom: 20px; } }
    .c-home-history__quotes svg {
      vertical-align: middle; }
      @media (max-width: 767.98px) {
        .c-home-history__quotes svg {
          width: 96px;
          height: 65px; } }
  .c-home-history__blockquote {
    background: #333232;
    border-top: 3px solid #FF4B31;
    padding: 60px;
    padding-top: 57px;
    box-shadow: 0 8px 40px 6px rgba(0, 0, 0, 0.4);
    max-width: 1050px;
    margin: auto; }
    @media (max-width: 991.98px) {
      .c-home-history__blockquote {
        padding: 40px;
        padding-bottom: 37px; } }
    @media (max-width: 767.98px) {
      .c-home-history__blockquote {
        padding: 20px; } }
    .c-home-history__blockquote-author {
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 30px; }
  .c-home-history .o-box-wrap {
    position: relative; }
  .c-home-history .c-carousel {
    margin: 0 -70px;
    text-align: right;
    position: relative;
    z-index: 1; }
    @media (max-width: 1399.98px) {
      .c-home-history .c-carousel {
        margin: 0 -40px; } }
    @media (max-width: 767.98px) {
      .c-home-history .c-carousel {
        margin: 0 -20px; } }
    .c-home-history .c-carousel-slider {
      z-index: 1;
      margin-bottom: 40px;
      text-align: left; }
      @media (max-width: 767.98px) {
        .c-home-history .c-carousel-slider {
          margin-bottom: 10px; } }
    .c-home-history .c-carousel-slide {
      padding: 20px 40px;
      padding-bottom: 40px; }
      @media (max-width: 767.98px) {
        .c-home-history .c-carousel-slide {
          padding: 20px;
          padding-bottom: 40px; } }
    .c-home-history .c-carousel-control {
      width: 33.3333%;
      margin-left: auto; }
      @media (max-width: 991.98px) {
        .c-home-history .c-carousel-control {
          width: 100%;
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;
          justify-content: space-between; } }
      @media (max-width: 600px) {
        .c-home-history .c-carousel-control {
          padding: 0 20px; } }

.c-customers {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding: 90px 70px;
  display: flex;
  align-items: center; }
  @media (max-width: 1399.98px) {
    .c-customers {
      padding: 60px 40px; } }
  @media (max-width: 1199.98px) {
    .c-customers {
      flex-wrap: wrap;
      justify-content: center; } }
  @media (max-width: 767.98px) {
    .c-customers {
      padding: 60px 20px; } }
  .c-customers-title {
    margin: 0;
    margin-right: 70px; }
    @media (max-width: 1399.98px) {
      .c-customers-title {
        margin-right: 40px; } }
    @media (max-width: 1199.98px) {
      .c-customers-title {
        text-align: center;
        margin: 0;
        margin-bottom: 40px; } }
  .c-customers-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 auto; }
    @media (max-width: 1199.98px) {
      .c-customers-list {
        margin: 0 -25px; } }
    @media (max-width: 767.98px) {
      .c-customers-list {
        margin: 0 -20px; } }
    @media (max-width: 600px) {
      .c-customers-list {
        flex-wrap: wrap;
        margin-bottom: -30px; } }
  .c-customers-item {
    padding: 0 55px; }
    @media (max-width: 1399.98px) {
      .c-customers-item {
        padding: 0 25px; } }
    @media (max-width: 767.98px) {
      .c-customers-item {
        padding: 0 20px; } }
    @media (max-width: 600px) {
      .c-customers-item {
        width: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px; } }
  .c-customers-link {
    filter: grayscale(1);
    opacity: 0.4; }
    .c-customers-link:hover, .c-customers-link:focus {
      opacity: 1;
      filter: none; }
  .c-customers img {
    max-width: 100%;
    height: auto; }
    @media (max-width: 600px) {
      .c-customers img {
        max-width: 100px;
        width: 100%; } }

.c-category {
  display: flex; }
  .c-category-wrap {
    width: 100%;
    display: flex;
    flex-direction: column; }
  .c-category-top {
    text-align: center;
    padding: 0 70px;
    margin-bottom: 80px;
    font-size: 1.125rem;
    line-height: 1.3333; }
    @media (max-width: 1399.98px) {
      .c-category-top {
        padding: 0 40px; } }
    @media (max-width: 1199.98px) {
      .c-category-top {
        margin-bottom: 40px; } }
    @media (max-width: 767.98px) {
      .c-category-top {
        padding: 0 20px;
        margin-bottom: 20px; } }
    .c-category-top__wrap {
      display: inline-flex; }
    .c-category-top__img {
      margin-right: 30px; }
      .c-category-top__img svg {
        vertical-align: middle; }
    .c-category-top__info {
      text-align: left; }
    .c-category-top__title {
      margin-bottom: 6px; }
      @media (max-width: 575.98px) {
        .c-category-top__title {
          font-size: 2rem; } }
  .c-category-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-grow: 1;
    width: 100%;
    text-align: center; }
    @media (max-width: 1199.98px) {
      .c-category-list {
        flex-wrap: wrap;
        text-align: left; } }
  .c-category-item {
    width: 33.3333%;
    display: flex; }
    @media (max-width: 1199.98px) {
      .c-category-item {
        width: 100%; } }
    @media (max-width: 1199.98px) {
      .c-category-item:first-child {
        border-top: 1px solid rgba(255, 255, 255, 0.15); } }
    @media (max-width: 1199.98px) {
      .c-category-item:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15); } }
    .c-category-item__link {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 70px;
      padding-bottom: 100px;
      color: #fff;
      position: relative; }
      @media (max-width: 1399.98px) {
        .c-category-item__link {
          padding-left: 40px;
          padding-right: 40px;
          padding-bottom: 60px; } }
      @media (max-width: 1199.98px) {
        .c-category-item__link {
          flex-direction: row;
          padding-top: 40px;
          padding-bottom: 40px; } }
      @media (max-width: 767.98px) {
        .c-category-item__link {
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 20px;
          padding-bottom: 20px; } }
      .c-category-item__link:hover, .c-category-item__link:focus {
        color: currentColor;
        text-decoration: none; }
        .c-category-item__link:hover:after, .c-category-item__link:focus:after {
          opacity: 1;
          visibility: visible; }
        .c-category-item__link:hover .c-category-item__title, .c-category-item__link:focus .c-category-item__title {
          opacity: 1; }
      .c-category-item__link:after {
        content: '';
        z-index: -1;
        width: 100%;
        height: 70%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: linear-gradient(to bottom, #2B2A2A 0%, #333232 100%);
        background-repeat: repeat-x;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease-in-out; }
        @media (max-width: 1199.98px) {
          .c-category-item__link:after {
            content: none; } }
    .c-category-item__img {
      width: 100%;
      margin-bottom: 80px;
      position: relative;
      text-align: center;
      flex-shrink: 0; }
      @media (max-width: 1199.98px) {
        .c-category-item__img {
          width: 33.3333%;
          max-width: 300px;
          margin-right: 30px;
          margin-bottom: 0; } }
      @media (max-width: 575.98px) {
        .c-category-item__img {
          max-width: 60px; } }
      .c-category-item__img:before {
        content: '';
        width: 100%;
        display: block;
        padding-bottom: 71.375%; }
      .c-category-item__img-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
      .c-category-item__img img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto; }
    .c-category-item__list {
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      opacity: .6; }
      .c-category-item__list-item {
        padding: 9px 0; }
        @media (max-width: 575.98px) {
          .c-category-item__list-item {
            padding: 4px 0; } }
    .c-category-item__title {
      margin-bottom: 30px;
      opacity: .6;
      transition: 0.3s ease-in-out; }
      @media (max-width: 767.98px) {
        .c-category-item__title {
          word-break: break-word;
          margin-bottom: 20px; } }
      @media (max-width: 1199.98px) {
        .c-category-item__title {
          opacity: 1; } }
      @media (max-width: 575.98px) {
        .c-category-item__title {
          font-size: 1.125rem;
          margin-bottom: 15px; } }
  @media (max-width: 1199.98px) {
    .c-category.u-line--left:before, .c-category.u-line--left:after, .c-category.u-line--right:before, .c-category.u-line--right:after {
      content: none; } }

.c-product {
  font-size: 1.125rem;
  line-height: 1.555556; }
  .c-product-top {
    padding-bottom: 80px;
    min-height: 100vh;
    display: flex;
    align-items: center; }
    @media (max-width: 1399.98px) {
      .c-product-top {
        padding-bottom: 60px; } }
    .c-product-top__wrap {
      display: flex; }
      @media (max-width: 1199.98px) {
        .c-product-top__wrap {
          flex-wrap: wrap; } }
    .c-product-top__col {
      padding: 0 20px; }
      @media (max-width: 1199.98px) {
        .c-product-top__col {
          padding: 0; } }
      .c-product-top__col.t-info {
        width: 58%;
        order: 1;
        margin-left: auto; }
        @media (max-width: 1199.98px) {
          .c-product-top__col.t-info {
            width: 100%;
            order: 0;
            margin-bottom: 60px; } }
      .c-product-top__col.t-sidebar {
        width: calc((100% + 140px) * 0.3333 - 70px);
        text-align: right; }
        @media (max-width: 1199.98px) {
          .c-product-top__col.t-sidebar {
            width: 100%;
            text-align: left; } }
    @media (max-width: 1199.98px) {
      .c-product-top.u-line--left:before {
        display: none; } }
  .c-product-sidebar {
    padding-right: 50px; }
    @media (max-width: 1399.98px) {
      .c-product-sidebar {
        padding-right: 20px; } }
    @media (max-width: 1199.98px) {
      .c-product-sidebar {
        display: flex;
        padding: 0;
        align-items: center; } }
    @media (max-width: 575.98px) {
      .c-product-sidebar {
        display: block; } }
    @media (max-width: 1199.98px) {
      .c-product-sidebar__category {
        width: 58%;
        padding: 0 20px; } }
    @media (max-width: 575.98px) {
      .c-product-sidebar__category {
        width: 100%;
        padding: 0; } }
    .c-product-sidebar__category-title {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 1rem;
      line-height: 1.375;
      font-weight: 600;
      margin-bottom: 21px;
      display: block;
      opacity: .6; }
    .c-product-sidebar__category-list {
      font-weight: 600;
      list-style: none;
      padding: 0;
      margin: 0; }
    .c-product-sidebar__category-item {
      padding: 7px 0; }
  .c-product-img {
    margin-bottom: 150px; }
    @media (max-width: 1199.98px) {
      .c-product-img {
        margin-bottom: 60px; } }
    @media (max-width: 1199.98px) {
      .c-product-img {
        width: 42%;
        padding: 0 20px;
        margin: 0; } }
    @media (max-width: 575.98px) {
      .c-product-img {
        width: 100%;
        padding: 0;
        margin-bottom: 60px; } }
    .c-product-img img {
      max-width: 100%;
      height: auto; }
  .c-product-info {
    max-width: 780px;
    width: 100%; }
  .c-product-title {
    margin-bottom: 60px; }
    @media (max-width: 575.98px) {
      .c-product-title {
        font-size: 1.875rem;
        line-height: 1.366667;
        margin-bottom: 30px; } }
  .c-product-description {
    margin-bottom: 60px; }
  .c-product-properties__list {
    list-style: none;
    padding: 0;
    margin: 0 -30px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -60px; }
    @media (max-width: 991.98px) {
      .c-product-properties__list {
        margin-left: -20px;
        margin-right: -20px; } }
  .c-product-properties__item {
    padding: 0 30px;
    text-align: center;
    margin-bottom: 60px; }
    @media (max-width: 991.98px) {
      .c-product-properties__item {
        padding: 0 20px; } }
  .c-product-properties__img {
    display: block;
    margin-bottom: 16px; }
    .c-product-properties__img img {
      max-width: 100%;
      height: auto; }
  .c-product-category {
    margin-bottom: 110px; }
    @media (max-width: 1399.98px) {
      .c-product-category {
        margin-bottom: 60px; } }
    .c-product-category__nav {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
      text-align: center; }
      @media (max-width: 1199.98px) {
        .c-product-category__nav {
          display: none; } }
      .c-product-category__nav-list {
        padding: 0;
        margin: 0 -40px;
        list-style: none;
        display: flex;
        justify-content: center; }
      .c-product-category__nav-item {
        max-width: 380px;
        flex-shrink: 0; }
      .c-product-category__nav-link {
        display: block;
        padding: 50px 40px;
        margin: 0;
        opacity: .6; }
        .c-product-category__nav-link:hover, .c-product-category__nav-link:focus {
          opacity: 1;
          color: #fff;
          text-decoration: none; }
        .c-product-category__nav-link.active {
          opacity: 1; }
          .c-product-category__nav-link.active > span:after {
            opacity: 1;
            visibility: visible; }
        .c-product-category__nav-link > span {
          display: block;
          position: relative; }
          .c-product-category__nav-link > span:after {
            content: '';
            width: 30px;
            height: 3px;
            background: #FF4B31;
            position: absolute;
            left: calc(50% - 15px);
            top: -13px;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease-in-out; }
    .c-product-category__content {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
      padding-top: 110px; }
      @media (max-width: 1399.98px) {
        .c-product-category__content {
          padding-top: 60px; } }
    .c-product-category__item {
      display: none; }
      @media (max-width: 1199.98px) {
        .c-product-category__item {
          display: block;
          opacity: 1; } }
      @media (max-width: 1199.98px) {
        .c-product-category__item:not(:last-child) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          margin-bottom: 60px;
          padding-bottom: 60px; } }
      .c-product-category__item.show {
        display: block; }
    .c-product-category__wrap {
      display: flex;
      margin-bottom: 86px; }
      @media (max-width: 1399.98px) {
        .c-product-category__wrap {
          margin-bottom: 36px; } }
      @media (max-width: 991.98px) {
        .c-product-category__wrap {
          flex-wrap: wrap; } }
    .c-product-category__col {
      padding: 0 20px; }
      @media (max-width: 991.98px) {
        .c-product-category__col {
          padding: 0; } }
      .c-product-category__col.t-info {
        width: 58%;
        order: 1;
        margin-left: auto; }
        @media (max-width: 991.98px) {
          .c-product-category__col.t-info {
            width: 100%;
            order: 0; } }
      .c-product-category__col.t-img {
        width: 42%; }
        @media (max-width: 991.98px) {
          .c-product-category__col.t-img {
            width: 100%; } }
    .c-product-category__title {
      margin-bottom: 40px; }
      @media (max-width: 575.98px) {
        .c-product-category__title {
          font-size: 1.875rem;
          line-height: 1.366667;
          margin-bottom: 30px; } }
    .c-product-category__info {
      max-width: 780px;
      width: 100%; }
    .c-product-category__img {
      padding-right: 30px;
      margin-bottom: 26px; }
      @media (max-width: 991.98px) {
        .c-product-category__img {
          padding: 0;
          margin-top: 40px; } }
    .c-product-category__properties {
      max-width: 1050px;
      margin: 0 auto; }
      .c-product-category__properties-title {
        text-align: center;
        font-size: 1.875rem;
        line-height: 1.366667;
        font-weight: 700;
        margin-bottom: 40px; }
        @media (max-width: 575.98px) {
          .c-product-category__properties-title {
            font-size: 1.5rem;
            line-height: 1.375;
            margin-bottom: 30px; } }
    .c-product-category .c-carousel-slider {
      margin-bottom: 60px; }
  .c-product dd {
    margin-bottom: 24px; }
  .c-product dt {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    line-height: 1.75;
    opacity: .6;
    font-weight: 600;
    float: left;
    margin-right: 10px; }
  .c-product p {
    margin-bottom: 24px; }
  .c-product table {
    font-size: 1rem;
    line-height: 1.375;
    font-weight: 400;
    width: 100% !important; }
    .c-product table td,
    .c-product table th {
      border: 1px solid rgba(255, 255, 255, 0.15);
      padding: 17px 25px; }
    .c-product table td {
      color: rgba(255, 255, 255, 0.8); }
    .c-product table th {
      font-weight: 700; }
    .c-product table thead tr {
      background-color: #333232; }
    .c-product table tbody tr:nth-of-type(even) {
      background-color: #333232; }
    .c-product table tbody tr:nth-of-type(odd) {
      background: none; }
  .c-product .o-box-wrap {
    width: 100%; }

.c-contacts-wrap {
  display: flex;
  align-items: center;
  padding-bottom: 200px; }
  @media (max-width: 1399.98px) {
    .c-contacts-wrap {
      padding-bottom: 60px; } }
  @media (max-width: 991.98px) {
    .c-contacts-wrap {
      flex-wrap: wrap;
      padding-bottom: 30px; } }

.c-contacts-col {
  padding: 0 70px; }
  @media (max-width: 1399.98px) {
    .c-contacts-col {
      padding: 0 40px; } }
  @media (max-width: 767.98px) {
    .c-contacts-col {
      padding: 0 20px; } }
  .c-contacts-col.t-list {
    width: 33.3333%;
    text-align: center; }
    @media (max-width: 991.98px) {
      .c-contacts-col.t-list {
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        margin-bottom: 40px;
        padding-bottom: 40px; } }
  .c-contacts-col.t-feedback {
    width: 66.6666%; }
    @media (max-width: 991.98px) {
      .c-contacts-col.t-feedback {
        width: 100%; } }

.c-contacts-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 240px;
  width: 100%;
  text-align: left;
  margin-top: 50px; }
  @media (max-width: 991.98px) {
    .c-contacts-list {
      margin-top: 0;
      text-align: center; } }

.c-contacts-item {
  display: block; }
  .c-contacts-item:not(:last-child) {
    margin-bottom: 30px; }
  .c-contacts-item__text {
    display: block;
    padding: 5px 0; }
  .c-contacts-item a {
    color: currentColor; }
    .c-contacts-item a:hover, .c-contacts-item a:focus {
      color: currentColor; }

.c-contacts-feedback {
  max-width: 780px;
  margin: 0 auto; }
  .c-contacts-feedback__title {
    margin-bottom: 60px;
    text-align: center; }
    @media (max-width: 991.98px) {
      .c-contacts-feedback__title {
        margin-bottom: 30px; } }
  .c-contacts-feedback textarea {
    height: 150px; }
    @media (max-width: 1399.98px) {
      .c-contacts-feedback textarea {
        height: 132px; } }

.c-contacts-map {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  height: 500px; }
  @media (max-width: 991.98px) {
    .c-contacts-map {
      height: 300px; } }

@media (max-width: 991.98px) {
  .c-contacts.u-line--left:before {
    display: none; } }

.c-about-top {
  background: url(../../src/svg/waves.svg) no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 60px;
  min-height: 100vh;
  display: flex;
  align-items: center; }
  @media (max-width: 1199.98px) {
    .c-about-top {
      min-height: auto; } }
  .c-about-top__wrap {
    max-width: 1370px;
    margin: 0 auto; }
  .c-about-top__type {
    display: block;
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 600; }
    @media (max-width: 1199.98px) {
      .c-about-top__type {
        margin-bottom: 30px; } }
  .c-about-top__title {
    max-width: 780px;
    margin-bottom: 60px; }
    @media (max-width: 1199.98px) {
      .c-about-top__title {
        margin-bottom: 30px; } }
    @media (max-width: 767.98px) {
      .c-about-top__title {
        font-size: 2.875rem;
        line-height: 1.26087; } }
  .c-about-top__text {
    font-size: 1.5rem;
    line-height: 1.6666;
    column-count: 2;
    column-gap: 80px; }
    @media (max-width: 1199.98px) {
      .c-about-top__text {
        font-size: 1.125rem; } }
    @media (max-width: 767.98px) {
      .c-about-top__text {
        column-count: 1;
        column-gap: 0; } }
  .c-about-top .o-box-wrap {
    width: 100%; }

.c-about-advantages {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  text-align: center; }
  .c-about-advantages__list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0; }
    @media (max-width: 1199.98px) {
      .c-about-advantages__list {
        flex-wrap: wrap; } }
  .c-about-advantages__item {
    width: 33.3333%;
    padding: 190px 60px;
    position: relative; }
    @media (max-width: 1399.98px) {
      .c-about-advantages__item {
        padding: 60px 30px; } }
    @media (max-width: 1199.98px) {
      .c-about-advantages__item {
        width: 100%;
        padding: 40px; } }
    @media (max-width: 767.98px) {
      .c-about-advantages__item {
        padding: 40px 20px; } }
    .c-about-advantages__item:hover:after {
      opacity: 1;
      visibility: visible; }
    .c-about-advantages__item:hover .btn {
      opacity: 1;
      visibility: visible;
      transform: none; }
    .c-about-advantages__item:after {
      content: '';
      z-index: -1;
      width: 100%;
      height: 70%;
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(to bottom, #2B2A2A 0%, #333232 100%);
      background-repeat: repeat-x;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-in-out; }
      @media (max-width: 1199.98px) {
        .c-about-advantages__item:after {
          display: none; } }
    @media (max-width: 1199.98px) {
      .c-about-advantages__item:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15); } }
  .c-about-advantages__icon {
    display: block;
    margin-bottom: 50px; }
    @media (max-width: 1199.98px) {
      .c-about-advantages__icon {
        margin-bottom: 30px; } }
  .c-about-advantages__title {
    margin-bottom: 40px; }
    @media (max-width: 1199.98px) {
      .c-about-advantages__title {
        margin-bottom: 30px; } }
  .c-about-advantages__text {
    display: block;
    margin-bottom: 60px;
    font-size: 1.5rem;
    line-height: 1.416667; }
    @media (max-width: 1199.98px) {
      .c-about-advantages__text {
        font-size: 1.125rem;
        line-height: 1.6666;
        margin-bottom: 30px; } }
  .c-about-advantages .btn {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px); }
    @media (max-width: 1199.98px) {
      .c-about-advantages .btn {
        opacity: 1;
        visibility: visible;
        transform: none; } }
  @media (max-width: 1199.98px) {
    .c-about-advantages.u-line--left:before, .c-about-advantages.u-line--left:after, .c-about-advantages.u-line--right:before, .c-about-advantages.u-line--right:after {
      display: none; } }

.c-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding: 80px 0; }
  @media (max-width: 1399.98px) {
    .c-footer {
      padding: 60px 0; } }
  @media (max-width: 767.98px) {
    .c-footer {
      padding: 40px 0; } }
  .c-footer-wrap {
    display: flex;
    align-items: flex-end; }
    @media (max-width: 1199.98px) {
      .c-footer-wrap {
        align-items: center;
        flex-wrap: wrap; } }
  .c-footer-nav {
    margin: 0 auto; }
    @media (max-width: 1199.98px) {
      .c-footer-nav {
        margin-bottom: 60px; } }
    @media (max-width: 600px) {
      .c-footer-nav {
        display: none; } }
    .c-footer-nav__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex; }
    .c-footer-nav__item.is-active .c-footer-nav__link {
      opacity: 1; }
      .c-footer-nav__item.is-active .c-footer-nav__link:after {
        opacity: 1;
        visibility: visible; }
    .c-footer-nav__link {
      color: currentColor;
      opacity: .6;
      padding: 0 20px;
      display: block;
      position: relative; }
      .c-footer-nav__link:hover, .c-footer-nav__link:focus {
        opacity: 1;
        color: currentColor;
        text-decoration: none; }
      .c-footer-nav__link:after {
        content: '';
        position: absolute;
        left: 20px;
        right: 20px;
        top: calc(100% + 8px);
        height: 3px;
        display: block;
        background: #FF4B31;
        visibility: hidden;
        opacity: 0; }
  .c-footer-logo, .c-footer-made {
    width: 20%; }
    @media (max-width: 1199.98px) {
      .c-footer-logo, .c-footer-made {
        width: 100%;
        text-align: center; } }
  @media (max-width: 1199.98px) {
    .c-footer-logo {
      margin-bottom: 40px; } }
  .c-footer-logo__link {
    display: block;
    margin-bottom: 4px; }
  .c-footer-made {
    font-size: 0.8125rem;
    line-height: 1.7;
    text-align: right; }
    @media (max-width: 1199.98px) {
      .c-footer-made {
        text-align: center; } }
    .c-footer-made__link {
      color: rgba(255, 255, 255, 0.6);
      display: inline-block; }
      .c-footer-made__link:hover, .c-footer-made__link:focus {
        color: #fff;
        text-decoration: none; }
    .c-footer-made img,
    .c-footer-made svg {
      vertical-align: baseline; }
  .c-footer .o-box:before, .c-footer .o-box:after {
    content: none; }

.modal .close {
  margin: auto;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: none;
  box-shadow: none;
  width: 58px;
  height: 58px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer; }
  @media (max-width: 767.98px) {
    .modal .close {
      margin-bottom: 30px;
      width: 48px;
      height: 48px; } }
  .modal .close:hover {
    border-color: #dc3545; }
    .modal .close:hover svg {
      opacity: 1;
      color: #dc3545; }
  .modal .close svg {
    opacity: .4;
    color: #fff;
    transition: 0.3s ease-in-out; }

.modal-content {
  border: none; }

.modal-title {
  margin-bottom: 18px; }

.modal-text {
  margin-bottom: 60px; }
  @media (max-width: 767.98px) {
    .modal-text {
      margin-bottom: 30px; } }

.modal textarea {
  height: 150px; }
  @media (max-width: 1399.98px) {
    .modal textarea {
      height: 132px; } }

.u-spacer-b {
  margin-bottom: 1rem !important; }

.u-spacer-b-sm {
  margin-bottom: 0.5rem !important; }

.u-spacer-b-lg {
  margin-bottom: 2rem !important; }

.u-gutter-x-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.u-gutter-l-sm {
  padding-left: 15px !important; }

.u-gutter-r-lg {
  padding-right: 15px !important; }

.u-m-0 {
  margin: 0 !important; }

.u-mt-0 {
  margin-top: 0 !important; }

.u-mb-0 {
  margin-bottom: 0 !important; }

.u-p-0 {
  padding: 0 !important; }

.u-pl-0 {
  padding-left: 0 !important; }

.u-pr-0 {
  padding-right: 0 !important; }

.u-nobr {
  white-space: nowrap; }

.u-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-top {
  padding-top: 170px; }
  @media (max-width: 1199.98px) {
    .u-top {
      padding-top: 113px; } }
  @media (max-width: 767.98px) {
    .u-top {
      padding-top: 190px; } }

.u-line--left,
.u-line--right {
  position: relative; }

.u-line--left:before {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(255, 255, 255, 0.15);
  left: 33.3333%; }

.u-line--right:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(255, 255, 255, 0.15);
  right: 33.3333%; }

/*# sourceMappingURL=app.css.map */